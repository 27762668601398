<script setup>
import { ref } from "vue";
import MainLayout from "@components/MainLayout.vue";
import ContentBox from "@components/ContentBox.vue";
import InputBox from "@components/InputBox.vue";
import ButtonBox from "@components/ButtonBox.vue";
import { request } from "@/modules/conn";
import { useGlobalStore } from "@/modules/store";
// import { useRouter } from "vue-router";
import { router } from "../modules/router";

const registerMode = ref(false);

const email = ref("");
const name = ref("");
const pass = ref("");
const passRepeat = ref("");

const submit = () => {
	if (registerMode.value) register();
	else login();
};

const register = async () => {
	if (!email.value) {
		alert("Zadajte email");
		return;
	}

	if (!name.value) {
		alert("Zadajte meno");
		return;
	}

	if (!pass.value) {
		alert("Zadajte heslo");
		return;
	}

	if (!passRepeat.value) {
		alert("Zadajte heslo znovu");
		return;
	}

	if (pass.value !== passRepeat.value) {
		alert("Heslá sa nezhoduju");
		return;
	}

	const result = await request("register", { email: email.value, name: name.value, password: pass.value });
	if (result.data.status) {
		loginSuccess(result.data.id, result.data.email, result.data.name, result.data.role, result.data.dateCreated);
	} else {
		alert("Chyba pri registrácii");
	}
};

const login = async () => {
	const result = await request("login", { email: email.value, password: pass.value });
	if (result.data.status) {
		loginSuccess(result.data.id, result.data.email, result.data.name, result.data.role, result.data.dateCreated);
	} else {
		alert("Nesprávne meno alebo heslo");
	}
};

const loginSuccess = (id, email, name, role, dateCreated) => {
	const globalStore = useGlobalStore();
	globalStore.setUser(id, email, name, role, dateCreated);
	router.replace("/profile");
};
</script>

<template>
	<main-layout>
		<content-box class="login-container">
			<div class="login">
				<div class="title">{{ registerMode ? "Registrácia" : "Prihlásenie" }}</div>

				<div class="link" @click="registerMode = !registerMode">
					<div v-if="!registerMode">Klikni sem pre registráciu</div>
					<div v-else>Klikni sem pre prihlásenie</div>
				</div>

				<input-box v-model="email" label="Email" />
				<input-box v-if="registerMode" v-model="name" label="Meno" />
				<input-box v-model="pass" label="Heslo" type="password" />
				<input-box v-if="registerMode" v-model="passRepeat" label="Zopakuje heslo" type="password" />
				<button-box class="button" @click="submit">Odoslať</button-box>
			</div>
		</content-box>
	</main-layout>
</template>

<style lang="scss">
.title {
	font-size: 30px;
}

.login-container {
	display: flex;
	justify-content: center;
	padding: 40px 0;

	.login {
		width: 300px;
		max-width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;

		.title {
			text-align: center;
		}

		.button {
			align-self: flex-end;
			margin-top: 20px;
		}

		.link {
			margin: 10px 0;
			> div {
				color: blue;
				text-decoration: underline;
				text-align: center;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}
</style>
