import axios from "axios";

export const request = async (name, data) => {
	var fromData = new FormData();
	for (const i in data) {
		fromData.append(i, data[i]);
	}

	// const res = await axios.post(`${name}.php`, fromData);
	const res = await axios.post(`/server/${name}.php`, fromData);

	return new Promise((resolve, reject) => {
		if (res.status === 200) {
			resolve(res);
		} else {
			reject({ status: false });
		}
	});
};
