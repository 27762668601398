<script setup>
import { ref, watch, computed, onMounted } from "vue";
import MainLayout from "@components/MainLayout.vue";
import ContentBox from "@components/ContentBox.vue";
import ButtonBox from "@components/ButtonBox.vue";
import InputBox from "@components/InputBox.vue";
import SelectBox from "@components/SelectBox.vue";
import { request } from "@/modules/conn";
import { useGlobalStore } from "@/modules/store";
import { storeToRefs } from "pinia";

const globalStore = useGlobalStore();
const { id, role, isLoggedIn } = storeToRefs(globalStore);
const date = ref(new Date());
const loading = ref(false);
const freeActivities = ref([""]);

const addAktivitaStatus = ref(false);
const aktivitaName = ref("");
const aktivitaTimeStart = ref("");
const aktivitaTimeEnd = ref("");
const sportoviskaOptions = ref([]);
const sportovisko = ref("");

const showActivities = async () => {
	loading.value = true;
	freeActivities.value = [];
	const array = await fetchActivities();
	loading.value = false;
	freeActivities.value = array;
};

const getDateString = () => {
	return date.value.toISOString().split("T")[0];
};

const fetchActivities = async () => {
	const result = await request("getActivities", { date: getDateString() });
	if (!result.data.status) return [];
	else return result.data.results;
};

const selectedDateLabel = computed(() => {
	return date.value.toLocaleDateString();
});

const reserve = async (aktivitaId) => {
	if (!globalStore.isLoggedIn) {
		alert("Pre rezerváciu sa musíte prihlásiť");
		return;
	}

	if (confirm("Rezervovať aktivitu?")) {
		loading.value = true;
		freeActivities.value = [];
		const result = await request("reserve", { userId: id.value, id: aktivitaId });
		if (!result.data.status) alert("Nastal problém pri rezervácií");
		showActivities();
	}
};

const remove = async (aktivitaId) => {
	if (!globalStore.isLoggedIn || !globalStore.role === "customer") {
		alert("Nemáte právo na vymazanie aktivity");
		return;
	}

	if (confirm("Vymazať aktivitu?")) {
		loading.value = true;
		freeActivities.value = [];
		const result = await request("deleteActivity", { id: aktivitaId });
		if (!result.data.status) alert("Nastal problém pri vymazaní aktivity");
		showActivities();
	}
};

const pridatAktivitu = async () => {
	if (!aktivitaName.value) {
		alert("Zadajte názov aktivity");
		return;
	}

	if (!sportovisko.value) {
		alert("Zadajte športovisko aktivity");
		return;
	}

	if (!aktivitaTimeStart.value) {
		alert("Zadajte čas začiatku aktivity");
		return;
	}

	if (!aktivitaTimeEnd.value) {
		alert("Zadajte čas konca aktivity");
		return;
	}

	loading.value = true;
	const result = await request("addActivity", {
		aktivitaName: aktivitaName.value,
		timeStart: formatTime(aktivitaTimeStart.value),
		timeEnd: formatTime(aktivitaTimeEnd.value),
		sportoviskoName: sportovisko.value,
		date: getDateString(date.value),
	});

	if (!result.data.status) {
		alert("Nastala chyba pri pridávaní");
		loading.value = false;
		return;
	}

	pridatReset();
	showActivities();
};

const pridatReset = () => {
	addAktivitaStatus.value = false;
	aktivitaName.value = "";
	aktivitaTimeStart.value = "";
	aktivitaTimeEnd.value = "";
	sportovisko.value = sportoviskaOptions.value[0];
};

const formatTime = (time) => {
	return `${time}:00`;
};

const getActivies = async () => {
	const res = await request("getSportoviska");
	if (!res.data.status) return;
	const list = res.data.results.map((item) => item.name) || [];
	sportoviskaOptions.value = list;
	sportovisko.value = sportoviskaOptions.value[0];
};

watch(
	() => date.value,
	(newDate, oldDate) => {
		if (!newDate) {
			date.value = oldDate;
			return;
		}
		pridatReset();
		showActivities();
	}
);

onMounted(() => {
	showActivities();
	getActivies();
});
</script>

<template>
	<main-layout>
		<content-box class="calendar-container">
			<div class="calendar">
				<div class="calendar-title">Kalendár pre športové aktivity</div>
				<VDatePicker outline expanded v-model="date" mode="date" />
				<div class="aktivity">
					<div class="title">Aktivity pre deň {{ selectedDateLabel }}:</div>

					<div v-if="id && role !== 'customer'" class="nova-aktivita-box">
						<button-box v-if="!addAktivitaStatus" @click="addAktivitaStatus = true"
							>Pridať aktivitu pre tento deň</button-box
						>
						<div v-else class="nova-aktivita-inputs">
							<input-box v-model="aktivitaName" label="Názov aktivity" />
							<select-box :options="sportoviskaOptions" v-model="sportovisko" label="Športovisko" />
							<input-box v-model="aktivitaTimeStart" type="time" label="Čas štart" />
							<input-box v-model="aktivitaTimeEnd" type="time" label="Čas koniec" />
							<button-box v-if="addAktivitaStatus" @click="pridatAktivitu" style="align-self: flex-end"
								>Pridať</button-box
							>
						</div>
					</div>

					<div v-if="!freeActivities.length && !loading" class="ziadne-aktivity">žiadne aktivity</div>
					<div v-if="loading" class="loading">Načítavanie...</div>

					<div v-for="(item, index) in freeActivities" :key="index" class="aktivita">
						<div class="left">
							<div class="meno">
								<span class="label">Názov:</span> <span class="value">{{ item.name }}</span>
							</div>
							<div class="sportovisko">
								<span class="label">Športovisko:</span>
								<span class="value">{{ item.sportovisko }}</span>
							</div>
							<div class="casy">
								<span class="label">Čas:</span>
								<span class="value">{{ item.timeStart }} - {{ item.timeEnd }}</span>
							</div>
							<div class="stav">
								<span class="label">Stav:</span>
								<span
									class="value"
									:style="{ color: item.reserved == 0 ? 'green' : 'rgb(189, 0, 0)' }"
									>{{ item.reserved == 0 ? "Voľné" : "Rezervované" }}</span
								>
							</div>
						</div>
						<div class="right">
							<img
								v-if="item.reserved == 0"
								@click="reserve(item.id)"
								src="@/assets/check.svg"
								alt="rezervovat"
							/>
							<img
								v-if="isLoggedIn && role !== 'customer'"
								@click="remove(item.id)"
								src="@/assets/delete.svg"
								alt="vymazat"
							/>
						</div>
					</div>
				</div>
			</div>
		</content-box>
	</main-layout>
</template>

<style lang="scss">
.title {
	font-size: 30px;
}

.calendar-container {
	display: flex;
	justify-content: center;
	padding: 40px 0;

	.calendar {
		width: 500px;
		max-width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;

		.calendar-title {
			font-weight: bold;
			font-size: 26px;
			text-align: center;
		}

		.aktivity {
			.title {
				font-size: 20px;
				margin-top: 10px;
				font-weight: bold;
			}

			.ziadne-aktivity {
				font-size: 18px;
				margin-top: 30px;
				color: rgb(189, 0, 0);
			}

			.nova-aktivita-box {
				margin: 20px 0;
				.nova-aktivita-inputs {
					display: flex;
					flex-direction: column;
					gap: 20px;
				}
			}

			.loading {
				font-size: 18px;
				margin-top: 20px;
				color: rgb(172, 146, 0);
			}

			.aktivita {
				padding: 20px;
				border: 1px solid #ccc;
				border-radius: 4px;
				margin-top: 20px;
				display: flex;
				align-items: center;

				.left {
					display: flex;
					flex-direction: column;
					gap: 5px;
					width: 70%;

					@media (max-width: 800px) {
						width: 80%;
					}

					div {
						display: flex;
						@media (max-width: 800px) {
							flex-direction: column;
						}

						.label {
							flex: 1;
						}

						.value {
							flex: 1;
							font-weight: bold;
						}
					}
				}

				.right {
					margin-left: auto;
					display: flex;
					flex-direction: column;
					gap: 10px;

					img {
						width: 40px;
						height: 40px;
						padding: 5px;

						&:hover {
							background: rgb(255, 223, 82);
							border-radius: 50%;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
</style>
