<script setup>
import { ref, onMounted } from "vue";
import MainLayout from "@components/MainLayout.vue";
import ContentBox from "@components/ContentBox.vue";
// import ButtonBox from "@components/ButtonBox.vue";
import { request } from "@/modules/conn";
// import { useGlobalStore } from "@/modules/store";
// import { router } from "../modules/router";
// import { storeToRefs } from "pinia";

// const globalStore = useGlobalStore();
// const { name, email, role, dateCreated, id } = storeToRefs(globalStore);

const usersList = ref([]);
const loading = ref(false);

const fetchUsers = async () => {
	loading.value = true;
	usersList.value = [];
	const res = await request("getUsers");
	loading.value = false;
	if (!res.data.status) return;
	const reservations = res.data.results || [];
	usersList.value = reservations;
};

const getRoleLabel = (value) => {
	if (value === "customer") return "Zákazník";
	else if (value === "moderator") return "Moderátor";
	return "Administrátor";
};

const getRoleColor = (value) => {
	if (value === "customer") return "green";
	else if (value === "moderator") return "blue";
	return "orange";
};

const changeRole = async (userData) => {
	const userId = userData.id;
	const newRole = userData.role === "customer" ? "moderator" : "customer";
	if (confirm("Zmeniť rolu na " + getRoleLabel(newRole) + "?")) {
		loading.value = true;
		const res = await request("changeRole", { userId, newRole });
		loading.value = false;

		if (!res.data.status) {
			alert("Nastala chyba pre zmene role.");
			return;
		}

		fetchUsers();
	}
};

onMounted(() => {
	fetchUsers();
});
</script>

<template>
	<main-layout>
		<content-box class="users-container">
			<div class="users">
				<div class="title">Registrovaní užívatelia:</div>
				<div v-if="loading" style="color: rgb(172, 146, 0)">Načítavam</div>
				<div v-else class="users-list">
					<div v-for="(item, index) in usersList" :key="index" class="user">
						<div class="top">
							<div>
								<span class="label">Meno:</span> <span class="value">{{ item.name }}</span>
							</div>
							<div>
								<span class="label">Email:</span>
								<span class="value">{{ item.email }}</span>
							</div>
							<div>
								<span class="label">Rola:</span>
								<span class="value" :style="{ color: getRoleColor(item.role) }">{{
									getRoleLabel(item.role)
								}}</span>
							</div>
							<div>
								<span class="label">Registrácia:</span>
								<span class="value">{{ item.dateCreated }}</span>
							</div>
						</div>
						<div v-if="item.role !== 'admin'" class="bottom">
							<div class="delete">Odstrániť</div>
							<div class="change" @click="changeRole(item)">Zmeniť rolu</div>
						</div>
					</div>
				</div>
			</div>
		</content-box>
	</main-layout>
</template>

<style lang="scss">
.title {
	font-size: 30px;
}

.users-container {
	display: flex;
	justify-content: center;
	padding: 40px 0;

	.title {
		font-weight: bold;
		font-size: 26px;
		margin-bottom: 10px;
	}

	.users {
		width: 500px;
		max-width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;
	}

	.users-list {
		.user {
			padding: 20px;
			border: 1px solid #ccc;
			border-radius: 4px;
			margin-top: 20px;
			display: flex;
			align-items: center;
			flex-direction: column;

			.top {
				display: flex;
				flex-direction: column;
				gap: 5px;
				width: 100%;

				> div {
					display: flex;
					padding: 5px 10px;

					@media (max-width: 800px) {
						flex-direction: column;
					}

					.label {
						flex: 1;
					}

					.value {
						flex: 1;
						font-weight: bold;
					}

					&:nth-of-type(even) {
						background-color: #f7f7f7;
					}
				}
			}

			.bottom {
				display: flex;
				width: 100%;
				border-top: 1px solid #ccc;
				margin-top: 20px;
				padding-top: 20px;

				> div {
					flex: 1;
					text-align: center;
				}

				.delete {
					color: red;
					font-weight: bold;

					&:hover {
						cursor: pointer;
						color: rgb(255, 63, 63);
					}
				}

				.change {
					color: rgb(153, 138, 8);
					font-weight: bold;

					&:hover {
						cursor: pointer;
						color: rgb(196, 180, 43);
					}
				}
			}
		}
	}
}
</style>
