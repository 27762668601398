<script setup>
import { storeToRefs } from "pinia";
import { ref, onMounted } from "vue";
import { RouterLink, useRouter } from "vue-router";
import { useGlobalStore } from "@/modules/store";

const globalStore = useGlobalStore();
const thisRoute = ref("");
const sideMenuOpen = ref(false);

const { role } = storeToRefs(globalStore);

const triggerMenu = () => {
	sideMenuOpen.value = !sideMenuOpen.value;
};

onMounted(() => {
	const router = useRouter();
	thisRoute.value = router.currentRoute.value.name;
});
</script>

<template>
	<div class="main">
		<div class="header-container">
			<div class="header">
				<router-link to="/">
					<div class="title">eSports</div>
				</router-link>
				<div class="menu">
					<router-link to="/">
						<div class="menu-link" :class="{ active: thisRoute === 'home' }">Domov</div>
					</router-link>
					<router-link to="/calendar">
						<div class="menu-link" :class="{ active: thisRoute === 'calendar' }">Kalendár</div>
					</router-link>
					<router-link to="/sportoviska">
						<div class="menu-link" :class="{ active: thisRoute === 'sportoviska' }">Športoviská</div>
					</router-link>
					<router-link v-if="role === 'admin'" to="/users">
						<div class="menu-link" :class="{ active: thisRoute === 'users' }">Užívatelia</div>
					</router-link>
				</div>

				<div class="user">
					<router-link :to="globalStore.isLoggedIn ? '/profile' : '/login'">
						<div class="menu-link" :class="{ active: thisRoute === 'login' || thisRoute === 'profile' }">
							{{ globalStore.isLoggedIn ? "Profil" : "Prihlásenie" }}
						</div>
					</router-link>
				</div>

				<div @click="triggerMenu" class="menu-mobile">
					<img src="@/assets/menu.svg" alt="menu" />
				</div>
			</div>
		</div>

		<div class="content">
			<slot />
		</div>

		<div
			v-show="sideMenuOpen"
			@click="
				(e) => {
					e.stopPropagation();
				}
			"
			class="side-menu"
		>
			<div class="side-menu-content">
				<div class="close-menu" @click="triggerMenu">Zavrieť menu</div>
				<div class="user">
					<router-link :to="globalStore.isLoggedIn ? '/profile' : '/login'">
						<div
							class="menu-link"
							@click="triggerMenu"
							:class="{ active: thisRoute === 'login' || thisRoute === 'profile' }"
						>
							{{ globalStore.isLoggedIn ? "Profil" : "Prihlásenie" }}
						</div>
					</router-link>
				</div>

				<div class="links">
					<router-link to="/">
						<div class="menu-link" @click="triggerMenu" :class="{ active: thisRoute === 'home' }">
							Domov
						</div>
					</router-link>
					<router-link to="/calendar">
						<div class="menu-link" @click="triggerMenu" :class="{ active: thisRoute === 'calendar' }">
							Kalendár
						</div>
					</router-link>
					<router-link to="/sportoviska">
						<div class="menu-link" @click="triggerMenu" :class="{ active: thisRoute === 'sportoviska' }">
							Športoviská
						</div>
					</router-link>
					<router-link v-if="role === 'admin'" to="/users">
						<div class="menu-link" @click="triggerMenu" :class="{ active: thisRoute === 'users' }">
							Užívatelia
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.main {
	min-width: 100vw;
	max-width: 100vw;
	min-height: 100vh;
	overflow-y: auto;
	background: #94e2ff;
	// padding: 20px;
	box-sizing: border-box;

	.side-menu {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 9999;
		height: 100vh;
		width: 100vw;
		display: flex;
		justify-content: flex-end;
		box-sizing: border-box;

		.side-menu-content {
			width: 300px;
			height: 100vh;
			max-width: 80%;
			background-color: white;
			-webkit-box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
			-moz-box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
			box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
		}

		.menu-link {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 0;
			text-align: center;

			&.active {
				background: rgb(173, 228, 255) !important;
			}

			&:hover {
				cursor: pointer;
				background-color: #def2fd;
			}
		}

		.close-menu {
			color: #494949;
			text-align: center;
			padding: 20px 0;
			background-color: #ffeb91;

			&:hover {
				cursor: pointer;
				background-color: #fff9df;
			}
		}
	}

	.header-container {
		background-color: #ffffff;
		-webkit-box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
		box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
	}

	.header {
		width: 800px;
		max-width: 90%;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		align-items: center;
		padding: 0 30px;
		height: 60px;

		.title {
			font-size: 22px;
			font-weight: bold;
			border-right: 1px solid #eeeeee;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-right: 30px;
		}

		.menu {
			display: flex;
			height: 100%;

			@media (max-width: 800px) {
				display: none;
			}
		}

		.menu-mobile {
			display: none;
			height: 100%;
			padding: 0 30px;
			align-items: center;
			margin-left: auto;
			overflow: hidden;

			&:hover {
				cursor: pointer;
				background-color: #def2fd;
			}

			@media (max-width: 800px) {
				display: flex;
			}
		}

		.menu-link {
			padding: 0 20px;
			height: 100%;
			display: flex;
			align-items: center;

			&.active {
				border-bottom: 3px solid rgb(114, 114, 114);
			}

			&:hover {
				cursor: pointer;
				background-color: #def2fd;
				border-bottom: 3px solid rgb(45, 177, 238);
			}
		}

		.user {
			margin-left: auto;
			display: flex;
			height: 100%;

			@media (max-width: 800px) {
				display: none;
			}

			.menu-link {
				padding: 0 30px;
				height: 100%;
				display: flex;
				align-items: center;

				&.active {
					border-bottom: 3px solid rgb(114, 114, 114);
				}

				&:hover {
					cursor: pointer;
					background-color: #def2fd;
					border-bottom: 3px solid rgb(45, 177, 238);
				}
			}
		}
	}

	.content {
		padding: 20px;
		width: 800px;
		max-width: 90%;
		margin: 0 auto;
	}
}

.v-enter-active,
.v-leave-active {
	transition: transform 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	transform: translateX(100%);
}
</style>
