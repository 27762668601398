<script setup>
import { ref, onMounted } from "vue";
import MainLayout from "@components/MainLayout.vue";
import ContentBox from "@components/ContentBox.vue";
import { request } from "@/modules/conn";

const list = ref([]);
const loading = ref(false);

const fetchSportoviska = async () => {
	loading.value = true;
	list.value = [];
	const res = await request("getSportoviska");
	loading.value = false;
	if (!res.data.status) return;
	const results = res.data.results || [];
	list.value = results;
};

onMounted(() => {
	fetchSportoviska();
});
</script>

<template>
	<main-layout>
		<content-box class="sportoviska-container">
			<div class="sportoviska">
				<div class="title">Zoznam športovísk:</div>
				<div v-if="loading" style="color: rgb(172, 146, 0)">Načítavam</div>
				<div v-else class="list">
					<div v-for="(item, index) in list" :key="index" class="sportovisko">
						<div>
							<span class="value">{{ item.name }}</span>
						</div>
					</div>
				</div>
			</div>
		</content-box>
	</main-layout>
</template>

<style lang="scss">
.title {
	font-size: 30px;
}

.sportoviska-container {
	display: flex;
	justify-content: center;
	padding: 40px 0;

	.title {
		font-weight: bold;
		font-size: 26px;
		margin-bottom: 10px;
	}

	.sportoviska {
		width: 500px;
		max-width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;
	}

	.list {
		.sportovisko {
			padding: 20px;
			background-color: #f8f8f8;
			border: 2px solid #ccc;
			border-radius: 4px;
			margin-top: 20px;
			display: flex;
			align-items: center;
			-webkit-box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.2);
			-moz-box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.2);
			box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.2);
		}
	}
}
</style>
