import { createApp } from "vue";
import App from "./App.vue";
import "./assets/css/normalize.css";
import "./assets/css/main.css";
import { router } from "@/modules/router";
import { createPinia } from "pinia";
import VCalendar from "v-calendar";
import "v-calendar/style.css";

const pinia = createPinia();
const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(VCalendar, {});

app.mount("#app");
