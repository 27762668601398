<script setup>
import { ref, onMounted } from "vue";
import MainLayout from "@components/MainLayout.vue";
import ContentBox from "@components/ContentBox.vue";
import ButtonBox from "@components/ButtonBox.vue";
import { request } from "@/modules/conn";
import { useGlobalStore } from "@/modules/store";
import { router } from "../modules/router";
import { storeToRefs } from "pinia";

const globalStore = useGlobalStore();
const { name, email, role, dateCreated, id } = storeToRefs(globalStore);

const reservationsList = ref([]);

const logout = () => {
	globalStore.resetUser();
	router.replace("/login");
};

const fetchMyReservations = async () => {
	reservationsList.value = [];
	const res = await request("getReservations", { userId: id.value });
	if (!res.data.status) return;
	const reservations = res.data.results || [];
	reservationsList.value = reservations;
};

const cancelReservation = async (aktivitaId) => {
	if (confirm("Zrušiť rezerváciu?")) {
		const result = await request("unreserve", { aktivitaId });
		if (result.data.status) {
			reservationsList.value = [];
			fetchMyReservations();
		} else {
			alert("Nastal problém pri zrušení rezervácie");
		}
	}
};

const getRoleLabel = (value) => {
	if (value === "customer") return "Zákazník";
	else if (value === "moderator") return "Moderátor";
	return "Administrátor";
};

const getRoleColor = (value) => {
	if (value === "customer") return "green";
	else if (value === "moderator") return "blue";
	return "orange";
};

onMounted(() => {
	fetchMyReservations();
});
</script>

<template>
	<main-layout>
		<content-box class="profil-container">
			<div class="profil">
				<div class="title">Vaše údaje:</div>
				<div class="infos">
					<div class="info">
						<span class="label">Meno:</span> <span class="value">{{ name }}</span>
					</div>
					<div class="info">
						<span class="label">Email:</span> <span class="value">{{ email }}</span>
					</div>
					<div class="info">
						<span class="label">Rola:</span>
						<span class="value" :style="{ color: getRoleColor(role) }">{{ getRoleLabel(role) }}</span>
					</div>
					<div class="info">
						<span class="label">Dátum registrácie:</span>
						<span class="value">{{ new Date(dateCreated).toLocaleDateString() }}</span>
					</div>
				</div>

				<button-box @click="logout" style="margin: 30px 0; align-self: flex-end">Odhlásiť</button-box>

				<div class="title">Vaše rezerácie:</div>
				<div v-if="!reservationsList.length" style="color: rgb(172, 146, 0)">
					Momentálne nemáte žiadne rezervácie
				</div>
				<div v-else class="aktivity-list">
					<div v-for="(item, index) in reservationsList" :key="index" class="rezervacia">
						<div class="left">
							<div class="meno">
								<span class="label">Názov:</span> <span class="value">{{ item.aktivita }}</span>
							</div>
							<div class="sportovisko">
								<span class="label">Športovisko:</span>
								<span class="value">{{ item.sportovisko }}</span>
							</div>
							<div class="casy">
								<span class="label">Čas:</span>
								<span class="value">{{ item.timeStart }} - {{ item.timeEnd }}</span>
							</div>
						</div>
						<div class="right">
							<img
								@click="cancelReservation(item.aktivitaId)"
								src="@/assets/cancel.svg"
								alt="odrezervovat"
							/>
						</div>
					</div>
				</div>
			</div>
		</content-box>
	</main-layout>
</template>

<style lang="scss">
.title {
	font-size: 30px;
}

.profil-container {
	display: flex;
	justify-content: center;
	padding: 40px 0;

	.title {
		font-weight: bold;
		font-size: 26px;
		margin-bottom: 10px;
	}

	.profil {
		width: 500px;
		max-width: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;

		.infos {
			.info {
				display: flex;
				padding: 10px;

				&:nth-of-type(even) {
					background-color: #f3f3f3;
				}

				.label {
					flex: 1;
				}

				.value {
					flex: 1;
					font-weight: bold;
					text-align: right;
				}
			}
		}
	}

	.aktivity-list {
		.rezervacia {
			padding: 20px;
			border: 1px solid #ccc;
			border-radius: 4px;
			margin-top: 20px;
			display: flex;
			align-items: center;

			.left {
				display: flex;
				flex-direction: column;
				gap: 5px;
				width: 70%;

				@media (max-width: 800px) {
					width: 80%;
				}

				div {
					display: flex;
					@media (max-width: 800px) {
						flex-direction: column;
					}

					.label {
						flex: 1;
					}

					.value {
						flex: 1;
						font-weight: bold;
					}
				}
			}

			.right {
				margin-left: auto;

				img {
					width: 40px;
					height: 40px;
					padding: 5px;

					&:hover {
						background: rgb(255, 223, 82);
						border-radius: 50%;
						cursor: pointer;
					}
				}
			}
		}
	}
}
</style>
