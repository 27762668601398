<script setup>
import { defineProps, defineModel } from "vue";
const props = defineProps({
	label: String,
	type: String,
});

const model = defineModel({
	required: true,
});

const handleChange = (e) => {
	const string = e.target.value;
	if (string) model.value = string;
};
</script>

<template>
	<div class="input-box">
		<label>{{ props.label }}</label>
		<input v-model="model" @input="handleChange" :type="type || 'text'" />
	</div>
</template>

<style lang="scss">
.input-box {
	label {
		display: block;
		margin-bottom: 5px;
	}
	input {
		display: block;
		outline: none;
		border: 1px solid #ccc;
		padding: 8px 12px;
		border-radius: 4px;
		width: 100%;
	}
}
</style>
