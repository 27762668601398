<template>
	<div class="box-inner center-h">
		<slot />
	</div>
</template>

<style lang="scss">
.box-inner {
	background: white;
	border-radius: 6px;
	overflow: hidden;
	-webkit-box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);

	* {
		color: black;
	}
}
</style>
