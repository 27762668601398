<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["click"]);
</script>

<template>
	<div class="button-box" @click="emit('click')">
		<slot />
	</div>
</template>

<style lang="scss">
.button-box {
	background-color: black;
	color: rgb(255, 241, 48);
	font-weight: bold;
	font-size: 18px;
	padding: 15px 25px;
	border-radius: 4px;
	width: fit-content;
	text-align: center;

	&:hover {
		background-color: rgb(58, 58, 58);
		cursor: pointer;
	}
}
</style>
