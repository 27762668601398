import { defineStore } from "pinia";

export const useGlobalStore = defineStore("global", {
	state: () => ({ id: "", email: "", name: "", role: "", dateCreated: "" }),
	getters: {
		isLoggedIn: (state) => {
			return state.id !== "" && state.email !== "" && state.name !== "" && state.role !== "";
		},
	},
	actions: {
		setUser(id, email, name, role, dateCreated) {
			this.id = id;
			this.email = email;
			this.name = name;
			this.role = role;
			this.dateCreated = dateCreated;
			this.saveToLocalStorage();
		},
		saveToLocalStorage() {
			if (this.id) localStorage.setItem("id", this.id);
			if (this.email) localStorage.setItem("email", this.email);
			if (this.name) localStorage.setItem("name", this.name);
			if (this.role) localStorage.setItem("role", this.role);
			if (this.dateCreated) localStorage.setItem("dateCreated", this.dateCreated);
		},
		getFromLocalStorage() {
			const id = localStorage.getItem("id");
			const email = localStorage.getItem("email");
			const name = localStorage.getItem("name");
			const role = localStorage.getItem("role");
			const dateCreated = localStorage.getItem("dateCreated");
			if ((id, email && name && role && dateCreated)) {
				this.setUser(id, email, name, role, dateCreated);
			}
		},
		resetLocalStorage() {
			localStorage.clear();
		},
		resetUser() {
			this.setUser("", "", "", "", "");
			this.resetLocalStorage();
		},
	},
});
