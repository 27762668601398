import { createMemoryHistory, createRouter } from "vue-router";
import { useGlobalStore } from "@/modules/store";

import { request } from "@/modules/conn";
import LoginPage from "@/pages/LoginPage.vue";
import ProfilePage from "@/pages/ProfilePage.vue";
import CalendarPage from "@/pages/CalendarPage.vue";
import SportoviskaPage from "../pages/SportoviskaPage.vue";
import UsersPage from "../pages/UsersPage.vue";
import HomePage from "../pages/HomePage.vue";

const routes = [
	{ path: "/", name: "home", component: HomePage },
	{ path: "/calendar", name: "calendar", component: CalendarPage },
	{ path: "/login", name: "login", component: LoginPage },
	{ path: "/profile", name: "profile", component: ProfilePage },
	{ path: "/sportoviska", name: "sportoviska", component: SportoviskaPage },
	{ path: "/users", name: "users", component: UsersPage },
];

export const router = createRouter({
	history: createMemoryHistory(),
	routes,
});

router.beforeEach(async (to, from) => {
	const globalStore = useGlobalStore();

	if (globalStore.id && from.name !== "login") {
		const result = await request("refreshUserData", { id: globalStore.id });
		if (!result.data.status) {
			globalStore.resetUser();
			alert("Boli ste odhlásený");
			return { path: "/login" };
		} else {
			globalStore.setUser(
				result.data.id,
				result.data.email,
				result.data.name,
				result.data.role,
				result.data.dateCreated
			);
		}
	}

	if (to.name === "profile" && !globalStore.isLoggedIn) {
		return { path: "/login" };
	}

	if (to.name === "login" && globalStore.isLoggedIn) {
		return { path: "/profile" };
	}
});
