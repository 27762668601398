<script setup>
import { onMounted } from "vue";
import { useGlobalStore } from "./modules/store";
onMounted(() => {
	const globalStore = useGlobalStore();
	globalStore.getFromLocalStorage();
});
</script>

<template>
	<router-view :key="$route.path" />
</template>

<style lang="scss"></style>
